<template>
  <div class="box-m-comment">
    <textarea
      :style="textareaStyle"
      placeholder="작성한 댓글은 수정이나 삭제를 할 수 없습니다."
      ref="commentTextarea"
      v-model="inputValue"
      @keyup.enter="setLine"
      @keyup.delete="setLine"
    />

    <img class="btn-m-comment size-13" @click="clickAdd" src="/static/icon/u_exclude.svg" />
  </div>
</template>

<script>
export default {
  name: 'TalkSend',
  data() {
    return {
      inputValue: '',
      textareaHeight: 32,
      minHeight: 40, // 최소 높이
      maxHeight: 80, // 최대 높이
      commentView: 1,
      vCommentAdd: false,
      selectedItem: undefined,
      editInput: '',
    };
  },
  created() {
    this.pageLoadType = 'infinite';
  },
  methods: {
    clickAdd() {
      this.$emit('clickAdd', this.inputValue);
      this.inputValue = '';
      this.vCommentAdd = false;
      this.setLine();
    },
    setLine() {
      let lineCount = this.inputValue.split(/\r|\r\n|\n/).length;
      if (lineCount > 0 && lineCount < 5) this.textareaHeight = 12 + lineCount * 20;
    },
  },
  computed: {
    mobileTextareaHeight() {
      let inuptVal = '';
      if (this.editInput !== '' && this.editingCommentId) {
        inuptVal = this.editInput;
      } else {
        inuptVal = this.inputValue;
      }
      const lines = inuptVal.split('\n').length; // 입력된 텍스트의 줄 수 계산
      const calculatedHeight = lines * 20; // 줄 수에 따른 높이 계산 (한 줄당 약 20px)
      let height;
      if (inuptVal.trim() === '' || inuptVal.length === 0) {
        height = this.minHeight;
      } else {
        // 입력된 텍스트의 높이가 자동으로 조절되도록 스크롤 높이값을 구함
        // 새 댓글 or 댓글 수정일 떄
        let computedHeight = null;
        if (this.$refs.commentTextareaEdit && this.editingCommentId) {
          computedHeight = this.$refs.commentTextareaEdit[0]
            ? this.$refs.commentTextareaEdit[0].scrollHeight
            : this.minHeight;
        } else {
          computedHeight = this.$refs.commentTextarea.scrollHeight;
        }
        height = Math.min(Math.max(calculatedHeight, this.minHeight), this.maxHeight * 20); // 최소/최대 높이 적용
        if (computedHeight >= height) {
          height = Math.min(computedHeight, this.maxHeight * 20);
        }
        height = height <= this.maxHeight ? height : this.maxHeight; // 최대 높이 초과 시 80px로 고정
      }
      return height + 'px';
    },
    textareaStyle() {
      let deco = {
        border: '1px solid #DDDDDD',
        borderRadius: '4px',
        padding: '4px 12px',
        width: '100%',
        height: '40px',
        fontSize: '15px',
        resize: 'none',
      };
      if (!this.isMobile) {
        deco.border = 'none';
        deco.outline = 'none';
        deco.padding = '0px';
        deco.height = '66px';
      } else {
        deco.height = this.mobileTextareaHeight;
        deco.maxHeight = this.maxHeight + 'px';
        deco.outline = 'none';
        deco.padding = '8px';
        deco.overflowY = 'auto';
        deco.fontSize = '14px';
      }
      return deco;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.box-m-comment
  position fixed
  bottom 0
  width 100%
  left 0
  padding 6px 12px
  background-color white
  border-top 1px solid #eeeeee
  display flex
  align-items center
  gap 8px

.btn-m-comment
  display flex
  align-items center
  justify-content center
  width 56px
  height 40px
  padding 6px 8px
  text-align center
  border-radius 4px
  color white
</style>
